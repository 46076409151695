import React from "react";
import SelectProducts from "./SelectProducts";
import Terms from "./Terms";
import { Form, Field } from "react-final-form";
import DropZone from "./Fields/DropZone";
import DatePickerPrint from "./Fields/DatePickerPrint";
import TextFieldCustom from "./Fields/TextFieldCustom";
import { IoSave, IoHourglassOutline } from "react-icons/io5";
import api from "../dataStores/api";
import {
  getSum,
  getImage,
  getDay,
  getProductImages,
  getProductDays,
} from "../Utils/adUtils";
import { Typography, SnackbarContent, Button } from "@mui/material";
import WarningEngine from "./Fields/WarningEngine";
import { getIssues } from "../Utils/utils";
import i18next from "i18next";
import styles from  "./AdForm.module.css";
import withNavigation from "../Utils/withNavigation";

function setNetsSrc(src) {
  const scriptTag = document.getElementById('nets-checkout-js');
  if (scriptTag) {
    scriptTag.src = src;
  }
}
const isSnapEnv = window.location.hostname.endsWith('.api.no');
const isLocalEnv = window.location.hostname.includes('localhost');
if (isSnapEnv || isLocalEnv) {
  setNetsSrc('https://test.checkout.dibspayment.eu/v1/checkout.js?v=1');
} else {
  setNetsSrc('https://checkout.dibspayment.eu/v1/checkout.js?v=1');
}

const required = (value) => (value ? undefined : "Dette må fylles ut");

export const isValidEmail = (value) => {
  // RFC 5322 compliant and extremely long email validation regex.
  // Catches all valid email addresses, including
  // the edgiest edge cases.
  const emailRegex =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!value || value === '') {
    return `E-post må fylles ut`;
  }
  if (emailRegex.test(value)) {
    return undefined;
  }
  return `E-post er ikke gyldig`;
};

const requiredMaxLength = (value) =>
  !value
    ? "Dette må fylles ut"
    : value.length > 150
    ? "Maks 150 tegn"
    : undefined;
const requiredMaxLengthTitle = (value) =>
  !value
    ? "Dette må fylles ut"
    : value.length > 20
    ? "Maks 20 tegn"
    : undefined;

const stripHtml = (str) => {
  if (str && typeof str === "string") {
    return str.replace(/<\/?[^>]+(>|$)/g, "").trim();
  }
  return "";
};

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

class AdForm extends React.Component {

  state = {
    open: false,
    ad: {},
  };

  constructor(props) {
    super(props);
    this.dropzoneRef = React.createRef();
  }

  onSubmit = async (adToSave) => {
    adToSave.adTitle = stripHtml(adToSave.adTitle);
    adToSave.adText = stripHtml(adToSave.adText);
    adToSave.alternativePrintDates = adToSave.alternativePrintDates
      ? stripHtml(adToSave.alternativePrintDates)
      : "";
    adToSave.firstname = stripHtml(adToSave.firstname);
    adToSave.orgname = adToSave.orgname ? stripHtml(adToSave.orgname) : "";
    adToSave.orgnr = adToSave.orgnr ? stripHtml(adToSave.orgnr) : "";
    adToSave.lastname = stripHtml(adToSave.lastname);
    adToSave.address = stripHtml(adToSave.address);
    adToSave.postalPlace = stripHtml(adToSave.postalPlace);
    adToSave.postalCode = stripHtml(adToSave.postalCode);
    adToSave.email = stripHtml(adToSave.email);
    adToSave.adContactInfo = stripHtml(adToSave.adContactInfo);
    adToSave.phone = stripHtml(adToSave.phone);
    adToSave.publication = isLocalhost ? `http://${window.location.host}` : `https://${window.location.host}`;
    const savedResult = await api("ad", "POST", adToSave);
    this.props.navigate("/betaling", {state: {savedAd: savedResult}});
  };

  getProductionDays(siteInfo) {
    let days = siteInfo.production ? siteInfo.production.days : 2;
    return " - " + days + (days > 1 ? " virkedager" : " virkedag");
  }

  toggleTerms() {
    this.setState({ open: !this.state.open });
  }

  checkImageId(products, values) {
    let image = getImage(products, values.product_id, values.image_id);
    if (!image || image.length === 0) {
      values.image_id = null;
    }
  }

  checkDayId(products, values) {
    let day = getDay(
      products,
      values.product_id,
      values.day_id,
      values.schema_type
    );
    if (!day || day.length === 0) {
      values.day_id = null;
    }
  }

  getInitialFormValues(products, ad, publicationKey) {
    let defaultProduct;
    if (products) {
      products.forEach((p) => {
        if (!defaultProduct && !p.organization) {
          defaultProduct = p;
        }
      });
    }
    let defaultProductId = defaultProduct ? defaultProduct._key : "";
    const images = getProductImages(products, defaultProductId);
    const defaultImage =
      images && images.length > 0 ? images[images.length - 1] : null;
    return Object.assign(
      {
        _type: "ad",
        product_id: defaultProductId,
        schema_type: "private",
        day_id: "",
        image_id: defaultImage ? defaultImage._key : "",
        publicationKey: publicationKey,
        totalPrice: 0,
      },
      ad
    );
  }

  render() {
    const { open, ad } = this.state;
    const { siteInfo, products, config } = this.props;
    const issues = getIssues(config.printDates, siteInfo.production);

    return (
          <div className={styles.wrapper}>
            <Terms
              {...this.props}
              open={open}
              toggle={this.toggleTerms.bind(this)}
              contactEmail={siteInfo.contactInfo ? siteInfo.contactInfo.email : ""}
            />
            <Form
              initialValues={this.getInitialFormValues(
                products,
                ad,
                config.publicationKey
              )}
              onSubmit={this.onSubmit}
              validateOnChange
              validate={(values) => {
                const errors = {};
                this.checkDayId(products, values);
                let productImages = getProductImages(products, values.product_id);
                if (productImages && productImages.length > 0) {
                  this.checkImageId(products, values);
                  if (!values.image_id) {
                    errors.body = "Du må velge antall dager med bilde";
                  }
                }
                if (!values.day_id) {
                  errors.body = "Du må velge antall dager i avisen";
                }
                return errors;
              }}
              render={({
                handleSubmit,
                submitting,
                values,
                submitError,
                dirtySinceLastSubmit,
                form,
              }) => {
                return (
                <form onSubmit={handleSubmit} className={styles.formContainer}>
                  <Typography
                    component={"h1"}
                    variant={"h4"}
                    gutterBottom
                  >
                    {"Bestilling av rubrikkannonse i papiravis"}
                  </Typography>

                  <Typography variant="body1">
                    {!siteInfo.useOrganization && (
                      <span style={{ fontStyle: "italic" }}>
                        Denne tjenesten er kun for privatpersoner.
                        <br />
                        <br />
                      </span>
                    )}
                    {!siteInfo.schemaInfoText && (
                      <span>
                        Denne tjenesten er kun for rubrikkannonsering. Annen
                        type annonsering kan du bestille på{" "}
                        <a
                          href={
                            "mailto:" +
                            (siteInfo.contactInfo
                              ? siteInfo.contactInfo.businessEmail
                              : "")
                          }
                        >
                          {" "}
                          {siteInfo.contactInfo
                            ? siteInfo.contactInfo.businessEmail
                            : ""}
                        </a>
                        <br />
                        <br />
                      </span>
                    )}
                    {siteInfo.schemaInfoText && (
                      siteInfo.schemaInfoText.split(/(\r\n|\n|\r)/).map(v => <p>{v}</p>)
                    )}
                    <span>
                      Bestillingsfrist er kl.{" "}
                      {siteInfo.production ? siteInfo.production.cutoff : "12"}
                      :00 {this.getProductionDays(siteInfo)} før annonsen skal
                      på trykk.
                      <br />
                      <br />
                    </span>
                    <span style={{ fontStyle: "italic" }}>
                      Felter merket med * må fylles inn
                    </span>
                  </Typography>

                  {siteInfo.useOrganization && (
                    <>
                      <Typography variant="h6" component="h2">
                        {i18next.t("order.type")} *
                      </Typography>
                      <SelectProducts
                        products={[
                          { _id: "private", title: "Privat" },
                          { _id: "organization", title: "Næring" },
                        ]}
                        selected={values.schema_type}
                        name={"schema_type"}
                      />
                    </>
                  )}
                    <Typography variant="h6" component="h2">
                      {i18next.t("order.category")} *
                    </Typography>
                  <SelectProducts
                    org={values.schema_type === "organization"}
                    products={products}
                    selected={values.product_id}
                    name={"product_id"}
                  ></SelectProducts>
                    <Typography variant="h6" component="h2">
                      {i18next.t("order.days")} *
                    </Typography>
                  <SelectProducts
                    products={getProductDays(
                      products,
                      values.product_id,
                      values.schema_type
                    )}
                    selected={values.day_id}
                    name={"day_id"}
                  ></SelectProducts>

                {getProductImages(products, values.product_id).length > 0 && (
                  <span>
                    <Typography variant="h6" component="h2">
                      {`Velg antall dager med bilde`}
                    </Typography>
                    <SelectProducts
                      products={getProductImages(products, values.product_id)}
                      selected={values.image_id}
                      name={"image_id"}
                    ></SelectProducts>
                  </span>
                )}
                <Field
                  name="image"
                  ad={ad}
                  validate={(value, allValues, meta) => {
                    if (allValues.day_id) {
                      const day = getDay(
                        products,
                        allValues.product_id,
                        allValues.day_id,
                        allValues.schema_type
                      );
                      if (
                        day &&
                        day.length > 0 &&
                        day[0].mandatoryImage &&
                        !allValues.image
                      ) {
                        return "Du må legge til et bilde";
                      }
                    }
                    return undefined;
                  }}
                  component={DropZone}
                  change={form.change}
                  blur={form.blur}
                  type="file"
                  label={
                    values.image
                      ? i18next.t("edit.image")
                      : i18next.t("new.image")
                  }
                  ref={this.dropzoneRef}
                />

                  <Typography variant="h6" component="h2">
                    {i18next.t("order.create")}
                  </Typography>

                  <Field
                    required
                    name="adTitle"
                    validate={requiredMaxLengthTitle}
                    component={TextFieldCustom}
                    type="text"
                    label={i18next.t("avisrubrikk.adTitle")}
                    variant="filled"
                  />

                  <Field
                    required
                    validate={requiredMaxLength}
                    name="adText"
                    component={TextFieldCustom}
                    type="text"
                    multiline
                    minRows="4"
                    label={i18next.t("avisrubrikk.adText")}
                  />

                  <Field
                    name="adContactInfo"
                    required
                    component={TextFieldCustom}
                    validate={required}
                    type="text"
                    label={i18next.t("avisrubrikk.visibleContactInfo")}
                    variant="filled"
                  />
                  <Field
                    name="printOn"
                    type="date"
                    validate={required}
                    component={DatePickerPrint}
                    issues={issues}
                    placeholder={i18next.t("new.printOn")}
                    label={i18next.t("new.printOn")}
                  />
                  <Field
                    name="alternativePrintDates"
                    component={TextFieldCustom}
                    type="text"
                    label={i18next.t("avisrubrikk.alternativePrintDates")}
                    variant="filled"
                  />

                    <Typography variant="h6" component="h2">
                      {i18next.t("order.info")}
                    </Typography>

                    {values.schema_type === "organization" && (
                      <div className={styles.flexrow}>
                        <Field
                          name="orgnr"
                          component={TextFieldCustom}
                          className={styles.flex1}
                          type="text"
                          label={i18next.t("avisrubrikk.orgnr")}
                          variant="filled"
                        />
                  
                        <Field
                          name="orgname"
                          required
                          component={TextFieldCustom}
                          validate={required}
                          className={styles.flex1}
                          type="text"
                          label={i18next.t("avisrubrikk.orgname")}
                          variant="filled"
                        />
                    </div>
                  )}
                  <div className={styles.flexrow}>
                      <Field
                        name="firstname"
                        required
                        component={TextFieldCustom}
                        className={styles.flex1}
                        validate={required}
                        type="text"
                        label={i18next.t("avisrubrikk.firstname")}
                        variant="filled"
                      />

                      <Field
                        name="lastname"
                        required
                        component={TextFieldCustom}
                        className={styles.flex1}
                        validate={required}
                        type="text"
                        label={i18next.t("avisrubrikk.lastname")}
                        variant="filled"
                      />
                  </div>

                      <Field
                        name="address"
                        required
                        component={TextFieldCustom}
                        validate={required}
                        type="text"
                        label={i18next.t("avisrubrikk.address")}
                        variant="filled"
                      />
                      <div className={styles.flexrow}>
                        <Field
                          name="postalCode"
                          required
                          component={TextFieldCustom}
                          className={styles.postalCode}
                          validate={required}
                          type="text"
                          label={i18next.t("avisrubrikk.postCode")}
                          variant="filled"
                        />
                        <Field
                          name="postalPlace"
                          required
                          component={TextFieldCustom}
                          className={styles.postalPlace}
                          validate={required}
                          type="text"
                          label={i18next.t("avisrubrikk.postalPlace")}
                          variant="filled"
                      />
                      </div>

                      <Field
                        name="phone"
                        required
                        component={TextFieldCustom}
                        validate={required}
                        type="text"
                        label={i18next.t("avisrubrikk.phone")}
                        variant="filled"
                      />

                      <Field
                        name="email"
                        required
                        component={TextFieldCustom}
                        validate={isValidEmail}
                        type="text"
                        label={i18next.t("avisrubrikk.email")}
                        variant="filled"
                      />

                      <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                        * obligatoriske felt
                      </div>
                      <div className={styles.summaryRow}>
                        <Typography
                          variant="h6"
                          component="p"
                        >
                          {`Kr ${getSum(products, values)},-`}
                        </Typography>
                        <div className={styles.flexrow}>
                          <Button
                            variant="outlined"
                            onClick={this.toggleTerms.bind(this)}
                            className={styles.termsButton}
                          >
                            {i18next.t("order.terms")}
                          </Button>
                            <Button
                              type="submit"
                              disabled={
                                submitting
                              }
                              variant="contained"
                              color="primary"
                              className={styles.submitButton}
                            >
                              <IoSave />
                              {i18next.t("payment.button")}
                            </Button>
                            {submitting && (
                              <IoHourglassOutline />
                            )}
                        </div>
                      </div>
                  {submitError && !dirtySinceLastSubmit && (
                    <div className={styles.errorWrapper}>
                      <SnackbarContent
                        message={submitError}
                      />
                    </div>
                  )}
                  <WarningEngine
                    products={products}
                  />
                </form>
              )}}
            />
        </div>
    )}
  }

export default withNavigation(AdForm);
