import React from "react";
import Dropzone from "react-dropzone";
import {IoImage} from "react-icons/io5";
import styles from "./DropZone.module.css";

import { IoHourglassOutline } from "react-icons/io5";

import {
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import {
  IMAGEMAXSIZE,
  ACCEPTEDFILETYPES,
  handleImageUpload,
  verifyFile,
} from "../../Utils/imageUtils";

class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openFileDialog: false, loading: false };
  }

  toggleOpenFileDialog = (value) => {
    this.setState({ openFileDialog: value });
  };

  toggleLoading = (value) => {
    this.setState({ loading: value });
  };

  handleKeyDown = (evt) => {
    if (evt.keyCode === 32 || evt.keyCode === 13) {
      this.toggleOpenFileDialog(true);
    }
  };

  render() {
    const {
      input,
      meta,
      change,
      blur,
      label,
      ad,
      dropzoneRef,
      ...rest
    } = this.props;

    console.log("dis",change);
    const showError =
      ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
      !this.state.openFileDialog;
    return (
      <div className={styles.preview}>
        <Dropzone
          ref={dropzoneRef}
          accept={ACCEPTEDFILETYPES}
          className={styles.drop}
          maxSize={IMAGEMAXSIZE}
          multiple={false}
          onBlur={(event) => blur("image")}
          onFocus={(event) => input.onFocus(event)}
          onChange={(event) => input.onChange(event)}
          onClick={() => this.toggleOpenFileDialog(true)}
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFileDialogCancel={() => this.toggleOpenFileDialog(false)}
          onDrop={(files) => {
            if (verifyFile(files)) {
              this.toggleLoading(true);
              handleImageUpload(files).then((imageAsset) => {
                change(
                  `${input.name}`,
                  {
                    _type: "image",
                    asset: {
                      _ref: imageAsset._id,
                      _type: "reference",
                    },
                  },
                  // eslint-disable-next-line no-param-reassign
                  (ad.imageUrl = imageAsset.url)
                );
                this.toggleLoading(false);
              });
            } else {
              alert("Ugyldig bildefil!");
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <FormControl
                error={showError}
                className={styles.dropForm}
              >
                <input
                  {...rest}
                  name={input.name}
                  onFocus={(event) => input.onFocus(event)}
                  {...getInputProps()}
                  required={false}
                />
                {ad.imageUrl && (
                  <img
                    alt="Preview"
                    className={styles.media}
                    src={`${ad.imageUrl}?&h=550&w=550`}
                  />
                )}
                <label
                  htmlFor={`${input.name}`}
                  className={styles.dropLabel}
                  required={showError}
                >
                  <Button
                    color="inherit"
                    className={styles.button}
                  >
                    <IoImage />
                    {label}
                    {this.state.loading && (
                      <IoHourglassOutline />
                    )}
                  </Button>
                </label>

                {showError && (
                  <FormHelperText>
                    {meta.error || meta.submitError}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default DropZone;
