import React from "react";
import { StoreConsumer } from "../ContextContainer/StoreContext";

import OrderDone from "./OrderDone";

class OrderDoneComponent extends React.Component {
  render() {
    return (
      <StoreConsumer>
        {(renderProps) => (
          <OrderDone
            {...this.props}
            products={renderProps.products}
            siteInfo={renderProps.siteInfo}
            config={renderProps.config}
          />
        )}
      </StoreConsumer>
    );
  }
}

export default OrderDoneComponent;
