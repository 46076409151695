import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div>
    <center>
      <Link to="/">Side ble ikke funnet, rykk tilbake til start</Link>
    </center>
  </div>
);

export default NotFound;
