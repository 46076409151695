const locales = {
  nb: {
    translation: {
      avisrubrikk: {
        adTitle: "Overskrift i annonse (maks 20 tegn)",
        adText: "Annonsetekst (maks 150 tegn)",
        visibleContactInfo:
          "Kontaktinfo som skal være synlig i annonsen (f.eks. tlf. eller e-post)",
        firstname: "Fornavn",
        lastname: "Etternavn",
        address: "Adresse",
        postCode: "Postnr",
        postalPlace: "Poststed",
        phone: "Telefon",
        email: "E-post",
        alternativePrintDates: "Du kan spesifisere flere trykkedatoer her",
        orgname: "Selskapsnavn",
        orgnr: "Organisasjonsnr",
      },
      order: {
        phone: "Hvordan bestille pr telefon/e-post",
        terms: "Se vilkår",
        create: "Lag din avisannonse",
        info: "Informasjon om deg",
        type: "Annonsetype",
        category: "Velg kategori",
        days: "Velg antall dager i avisen",
      },
      home: "Tilbake",
      loading: "Vent litt mens vi henter frem ting til deg",
      warning: {
        body: "Det ble litt for langt. Fjern litt, så får vi plass i avisen.",
      },
      new: {
        email: "Epostadressen din",
        recipientName: "Navn på mottaker",
        printOn: "Første ønsket trykkedato i avis?",
        image: "Legg til bilde (maks 10MB)",
      },
      payment: {
        button: "Til betaling",
        status: `Annonsen kostet {{price}},- og ble betalt {{paidAt}}, med ordrenummer #{{orderno}}`,
      },
      edit: {
        image: "Bytte til et annet bilde? (maks 10MB)",
      },
    }
  }
  ,
  nn: {
    translation: {
      avisrubrikk: {
        adTitle: "Overskrift i annonse (maks 20 teikn)",
        adText: "Annonsetekst (maks 150 teikn)",
        visibleContactInfo:
          "Kontaktinfo som skal vere synleg i annonsen (f.eks. tlf. eller e-post)",
        firstname: "Førenamn",
        lastname: "Etternamn",
        address: "Adresse",
        postCode: "Postnr",
        postalPlace: "Poststad",
        phone: "Telefon",
        email: "E-post",
        alternativePrintDates: "Du kan spesifisere fleire trykkedatoar her",
        orgname: "Selskapsnamn",
        orgnr: "Organisasjonsnr",
      },
      order: {
        phone: "Korleis bestille pr. telefon/e-post",
        terms: "Sjå vilkår",
        create: "Lag din avisannonse",
        info: "Informasjon om deg",
        type: "Annonsetype",
        category: "Vel kategori",
        days: "Vel tal dagar i avisa",
      },
      home: "Tilbake",
      loading: "Lastar, vent litt...",
      warning: {
        body: "Det vart litt for langt. Fjern litt, så får vi plass i avisa.",
      },
      new: {
        choose: "Vel type helsing",
        email: "E-postadressa di",
        printOn: "Første ønskte trykkedato i avis",
        image: "Legg til bilete (maks 10 MB)",
      },
      payment: {
        button: "Til betaling",
        status: `Annonsen kosta {{price}}-. Den vart betalt {{paidAt}}, med ordrenummer #{{orderno}}`,
      },
      edit: {
        image: "Bytte til eit anna bilete? (maks 10 MB)",
      },
    },
  }
};


export default locales;
