import React from "react";

import api from "../dataStores/api";
import { displayPaidAt } from "../Utils/dateUtils";
import {
  Typography,
  Divider,
  SnackbarContent,
  Button,
} from "@mui/material";
import { IoAddCircle, IoCheckmarkCircleOutline, IoReceipt} from "react-icons/io5";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import styles from "./OrderDone.module.css";

class OrderDone extends React.Component {
  state = {
    createdAd: {},
    createdTransaction: {},
  };

  componentDidMount() {
    let adId = this.props.adId;
    if (adId) {
      this.setState({ openOrderDone: true });
      api(`ad?adId=${adId}`).then((a) =>
        this.setState({ createdAd: a })
      );
      api(`ad/imageurl?adId=${adId}`).then((a) =>
          this.setState({ image: a })
      );
    }
  }

  getDays(ad) {
    return ad && ad.receipt && ad.receipt.dayOptions
      ? ad.receipt.dayOptions[0].title
      : "";
  }

  getImages(ad) {
    return ad && ad.receipt && ad.receipt.imageOptions && ad.receipt.imageOptions[0]
      ? ad.receipt.imageOptions[0].title
      : "";
  }

  render() {
    const { createdAd, image } = this.state;

    let imgText = "";

    if (this.getImages(createdAd) !== "") {
      imgText = `Antall dager med bilde: ${this.getImages(createdAd)}<br/>`;
    }
    return (
      <div>
        <Typography
          variant="subtitle1"
          component="h2"
          className={styles.receiptHeading}
        >
          <IoReceipt /> &nbsp;
          Kvittering - Din bestilling er nå gjennomført!
        </Typography>
        <div>
            {createdAd && (
              <SnackbarContent
                className={styles.snackbar}
                message={
                  <Typography component="span" variant="body1">
                    <IoCheckmarkCircleOutline />&nbsp;
                    <span>
                      {`${i18next.t(`payment.status`, {
                        price: createdAd.totalPrice,
                        paidAt: displayPaidAt(createdAd._updatedAt),
                        orderno: `${
                            createdAd.publicationKey + "-" + createdAd._id
                        }`,
                      })}`}
                    </span>
                  </Typography>
                }
              />
            )}
          <div className={styles.buttonWrapper}>
            <Button href={"/avisrubrikk"} color="primary" variant="outlined" className={styles.newAdButton}>
              <IoAddCircle /> Lag ny annonse
            </Button>
          </div>
          <hr className={styles.hr} />
          {this.getImages(createdAd) !== "" && (
            <div>
              <img
                imageStyle={{ height: "inherit", width: "90%" }}
                src={image ? image : ""}
              />
            </div>
          )}
          <div>
            <div className={styles.content}>
              <Typography component="h6" variant="h6">
                Overskrift i annonsen: {createdAd ? createdAd.adTitle : ""}
              </Typography>
              <hr className={styles.hr} />
              <Typography
                component="span"
                variant="subtitle1"
              >
                Annonsetekst: {createdAd ? createdAd.adText : ""}
                <br />
                Kontaktinfo som skal være synlig i annonsen:{" "}
                {createdAd ? createdAd.adContactInfo : ""}
                <br />
                Antall dager i avisen: {this.getDays(createdAd)}
                <br />
                {imgText}
                Første ønsket trykkedato i avisen:{" "}
                {displayPaidAt(createdAd ? createdAd.printOn : "")}
                <br />
                Flere trykkedatoer: {createdAd ? createdAd.alternativePrintDates : ""}
                <br />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const withAdId = (WrappedComponent)=> (props)=> {
  let { adId } = useParams();

    return <WrappedComponent adId={adId} {...props} />;
}

export default withAdId(OrderDone);
