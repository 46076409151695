import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { IoClose } from "react-icons/io5";
import styles from "./DialogBox.module.css"

const DialogTitle = ((props) => {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={styles.titleWrapper}>
      <Typography
        variant="subtitle1"
        component="span"
      >
        {children}
      </Typography>
      <IconButton
        aria-label="Close"
        onClick={onClose}
      >
        <IoClose />
      </IconButton>
    </MuiDialogTitle>
  );
});

class DialogBox extends React.Component {
  render() {
    const {
      fullScreen = false,
      title,
      handleClose,
      icon,
      maxWidth = "md",
      disableEscapeKeyDown = false,
      hideClose = false,
    } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={maxWidth}
        scroll={"body"}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={disableEscapeKeyDown}
        fullScreen={fullScreen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          aria-label="Lukk"
          hideClose={hideClose}
        >
          {icon}
          {title}
        </DialogTitle>
        <DialogContent>
          {this.props.children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default DialogBox;
