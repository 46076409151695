export const getProductDays = (products, productId, schemaType) => {
  let filter = products.filter(
    (product) =>
      (schemaType === undefined
        ? true
        : schemaType === "private"
        ? !product.organization
        : product.organization) && product._key === productId
  );
  return filter[0] && filter[0].options ? filter[0].options.dayOptions : [];
};

export const getProductImages = (products, productId) => {
  let filter = products.filter((product) => product._key === productId);
  return filter[0] && filter[0].options && filter[0].options.imageOptions
    ? filter[0].options.imageOptions
    : [];
};

export const getDayPrice = (products, productId, dayId, schemaType) => {
  let day = getDay(products, productId, dayId, schemaType);
  return day && day[0] ? day[0].price : 0;
};

export const getDay = (products, productId, dayId, schemaType) => {
  let days = getProductDays(products, productId, schemaType);
  let filter = days.filter((day) => day._key === dayId);
  return filter;
};

export const getImage = (products, productId, imageId) => {
  let img = getProductImages(products, productId);
  let filter = img.filter((i) => i._key === imageId);
  return filter;
};

export const getImagePrice = (products, productId, imageId) => {
  let img = getImage(products, productId, imageId);
  return img && img[0] ? img[0].price : 0;
};

export const getSum = (products, values) => {
  let sum =
    getDayPrice(
      products,
      values.product_id,
      values.day_id,
      values.schema_type
    ) + getImagePrice(products, values.product_id, values.image_id);
  values.totalPrice = sum;
  return sum;
};
