import React from "react";

function WaitSpinner() {
  return (
    <div>
       <span>{"Vent litt..."}</span>
    </div>
  );
}

export default WaitSpinner;
