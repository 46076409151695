const apiBasePath = `/api/annonsekjop/v1/avisrubrikk/`;

export const api = async (url, method, ad) => {
  let response;
  if (ad) {
    response = await fetch(`${apiBasePath}/${url}`, {
      method,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(ad),
    });
  } else {

      response = await fetch(`${apiBasePath}/${url}`, {
      method,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
  }

  if (response.status === 201 || response.status === 200) {
    return response.json();
  }
  throw new Error(response.status);
};

export default api;
