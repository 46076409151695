import axios from "axios";

const IMAGEMAXSIZE = 10000000;
const ACCEPTEDFILETYPES =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";

const acceptedFileTypeArray = ACCEPTEDFILETYPES.split(",").map((item) =>
  item.trim()
);

const verifyFile = (files) => {
  if (files && files.length > 0) {
    const currentFile = files[0];
    const currentFileType = currentFile.type;
    const currentFileSize = currentFile.size;
    if (currentFileSize > IMAGEMAXSIZE) {
      return false;
    }
    if (!acceptedFileTypeArray.includes(currentFileType)) {
      return false;
    }
    return true;
  }
  return false;
};

const handleImageUpload = (files, rejectedFiles) => {
  if (rejectedFiles && rejectedFiles.length > 0) {
    verifyFile(rejectedFiles);
  }

  if (files && files.length > 0) {
    const isVerified = verifyFile(files);
    if (isVerified) {
      const formData = new FormData();
      formData.append("image", files[0], files[0].name);

      return axios
        .post("/api/annonsekjop/v1/avisrubrikk/upload", formData)
        .then((res) => res.data);
    }
  }
};

export {
  IMAGEMAXSIZE,
  ACCEPTEDFILETYPES,
  acceptedFileTypeArray,
  verifyFile,
  handleImageUpload,
};
