const amediaConfigDev = {
  serverType: "dev",
  servicesHost: "bed.localhost.api.no",
  dataset: "everything-test",
  useCdn: true,
  publicationKey: "avisna",
};

const config = (window) => {
  if (window && window.amediaConfig) {
    return Object.assign({
      dataset:
        window.amediaConfig.serverType !== "production"
          ? "everything-test"
          : "production",
      servicesHost: window.amediaConfig.servicesHost,
      publicationKey: window.amediaConfig.publicationKey,
      useCdn: true,
    });
  }
  if (window && !window.amediaConfig) {
    console.log(
      "Avisrubrikk: no window.amediaConfig found, falling back to development defaults"
    );
  }
  return amediaConfigDev;
};

export default config;
