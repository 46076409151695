import React from "react";
import { TextField } from '@mui/material';

export default ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched &&
    !meta.active;
  const showWarning = meta.data.warning && meta.touched && !meta.active;
  const showInfo =
    (meta.touched || meta.active) && meta.data.info ? meta.data.info : "";
  const dispWarningText = showWarning ? meta.data.warning : showInfo;
  return (
    <TextField
      {...restInput}
      {...rest}
      id={name}
      name={name}
      onChange={onChange}
      helperText={showError ? meta.error : dispWarningText}
      value={value}
      error={
        (meta.error && meta.dirty) ||
        (meta.error && meta.touched && !meta.active)
      }
      variant="outlined"
      fullWidth
    />
  );
};
