import React from "react";
import withNavigation from "../Utils/withNavigation";

class PaymentComponent extends React.Component {
    runCheckout = async (checkoutId, paymentId) => {
        return new Promise((resolve, reject) => {
            const checkoutOptions = {
                checkoutKey: checkoutId,
                paymentId: paymentId,
                containerId: 'checkout-container-div',
                language: 'nb-NO',
                checkout: {
                    integrationType: 'EmbeddedCheckout',
                    termsUrl: `https://${window.location.host}/terms.html`,
                },
            };
            const checkout = new window.Dibs.Checkout(checkoutOptions);
            checkout.on('payment-completed', (response) => {
                resolve(checkoutId, paymentId);
            });
        });
    };

    componentDidMount() {
        window.scrollTo(0,0);
        const {savedAd} = this.props.location.state;
        this.runCheckout(savedAd.checkoutId, savedAd.paymentId).then(() => {
            this.props.navigate("/payed/" + savedAd._id);
        });
    }

    render() {
        return (
            <>
                <div id="checkout-container-div"></div>
            </>
        );
    }
}

export default withNavigation(PaymentComponent);
