import {
  compareAsc,
  addDays,
  getHours,
  format,
  isSunday,
  isSaturday,
  setHours,
  startOfDay
} from "date-fns";

const byteToHex = [];
for (let i = 0; i < 256; ++i) {
  byteToHex[i] = (i + 0x100).toString(16).substring(1);
}

const isProductionDay = (date, productionCutoff) => {
  return (
    !isSaturday(date) &&
    !isSunday(date) &&
    getHours(date) < Number(productionCutoff === 0 ? 0.1 : productionCutoff)
  );
};

const getProductionDate = (date, productionCutoff) => {
  if (isProductionDay(date, productionCutoff)) {
    return date;
  }
  const nextDay = addDays(date, 1);
  return getProductionDate(setHours(nextDay, 0), productionCutoff);
};

const getFirstIssueDate = (date, product) => {
  const productionCutoff =
    product && product.cutoff ? Number(product.cutoff) : 0;
  const productionDays = product && product.days ? Number(product.days) : 0;
  let productionDate = new Date(date);

  productionDate = getProductionDate(productionDate, productionCutoff);

  for (let d = 1; d < productionDays; d++) {
    productionDate = addDays(productionDate, 1);
    productionDate = getProductionDate(productionDate, productionCutoff);
  }

  let d = startOfDay(addDays(productionDate, 1));
  return d;
};

const getIssues = (issues, product, { timeNow = new Date() } = {}) => {
  if (!product.days) return [];
  if(!issues || !issues.length) return [];
  // We are omitting 'Z' as including this in our format would make it UTC time and not local.
  const firstIssueDate = getFirstIssueDate(timeNow, product);
  const futureIssues = issues
      .map(issue => startOfDay(new Date(issue)))
      .sort(compareAsc)
      .reduce((array, issue) => {
          if (issue >= firstIssueDate) array.push(format(issue, 'yyyy-MM-dd'));
          return array
      }, []);
  return futureIssues;
};

export { getIssues };
