import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { addDays, format } from "date-fns";
import nb from "date-fns/locale/nb";
import styles from "./DatePickerPrint.module.css";

registerLocale("nb", nb);

export default ({
  input: { name, onChange, onBlur, value, ...restInput },
  meta,
  label,
  issues,
  fullWidth,
  margin,
  ...rest
}) => {
  if (issues.length === 0) {
    return (<span></span>);
  }
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched &&
    !meta.active;
  const showWarning =
    (meta.data.warning && !meta.dirty) ||
    (meta.data.warning && meta.dirty && !meta.touched)
      ? meta.data.warning
      : undefined;

  const isPublicationDate = (date) =>
    issues.includes(format(date, "yyyy-MM-dd"));

  const getFirstValidDate = (date) =>
      isPublicationDate(date) ? date : getFirstValidDate(addDays(date, 1));

  const [startDate, setStartDate] = useState(getFirstValidDate(new Date()));

  useEffect(() => {
    onChange(startDate)
  });

  const changeDate = (date) => {
    setStartDate(date);
    onChange(date);
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      error={showError}
      onBlur={onBlur}
    >
      <label htmlFor={name} className={styles.dateLabel} required>
        {label}
      </label>

      <DatePicker
        {...rest}
        {...restInput}
        name={name}
        locale={"nb"}
        calendarClassName={styles.datePicker}
        dateFormat="yyyy-MM-dd"
        onBlur={onBlur}
        onChange={changeDate}
        selected={startDate}
        maxDate={
          issues && issues.length > 0
            ? new Date(issues[issues.length - 1])
            : new Date()
        }
        filterDate={isPublicationDate}
        inline
      />
      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
      {showWarning && <FormHelperText>{meta.data.warning}</FormHelperText>}
    </FormControl>
  );
};
