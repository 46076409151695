import React from "react";
import Typography from '@mui/material/Typography';
import { Field } from "react-final-form";
// import InputLabel from '@mui/base/InputLabel';
import styles from "./SelectProducts.module.css";

import Radio from "./Fields/Radio";

function SelectProducts(props) {
  const { products, selected, name, org } = props;
  return (
    <div className={styles.wrapper}>
      {products
      .filter((p) => (org && p.organization) || (!org && !p.organization))
      .map((product) => (
        <label
          htmlFor={`${getId(product)}`}
          className={`${styles.radioButtonLabel} ${selected === getId(product) && styles.selected}`}
          key={`label_${getId(product)}`}
        >
          <Field
            name={name}
            id={getId(product)}
            type="radio"
            component={Radio}
            value={getId(product)}
            selected={selected === getId(product)}
            autoFocus={selected === getId(product)}
            product={product}
            required="true"
          />
          <div className={styles.productInfo}>
            <div>
              <Typography variant="subtitle1" component="div">
                {product.title}
              </Typography>
              <Typography variant="subtitle2" component="div">
                {product.description}
              </Typography>
            </div>
            <Typography
              variant="subtitle1"
              className={styles.productPrice}
              component="div"
            >
              {product.price ? `Kr ${product.price},-` : ""}
            </Typography>
          </div>
        </label>
      ))}
    </div>

  );
}

function getId(product) {
  return product._id ? product._id : product._key;
}

export default SelectProducts;
