import {format, getYear} from "date-fns"

export const displayPaidAt = (paidAt, { timeNow = new Date() } = {}) => {
  const formatDate =
    getYear(new Date(timeNow)) === getYear(new Date(paidAt))
      ? "d MMMM"
      : "d MMMM yyyy";
  return format(paidAt ? new Date(paidAt) : new Date(), `${formatDate}`);
};

