import React from "react";

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import StoreContext from "./ContextContainer/StoreContext";
import Content from "./Content";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        border: 0,
        textTransform: "none",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#4CAF50",
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "rgba(0,0,0,0.08)",
        margin: 0,
        padding: 8,
      },
      root: {
        margin: 0,
        color: "#4b636e",
        fontSize: "inherit",
        backgroundColor: "rgba(0,0,0,0.08)",
        padding: 8,
      },
    },
    MuiDialogContent: {
      root: {
        borderRadius: 0,
      },
    },
    MuiModal: {
      root: {
        zIndex: 9999999,
      },
    },
    MuiListItem: {
      root: {
        fontSize: "1rem",
        useNextVariants: true,
        fontFamily: [
          '"Open Sans"',
          "sans-serif",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
  },
  palette: {
    primary: {
      main: "#263238",
    },
    secondary: {main: "#ff1744"},
  },
  typography: {
    fontSize: 16,
    useNextVariants: true,
    fontFamily: [
      '"Open Sans"',
      "sans-serif",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none"
    },
  },
});

const App = () => (
  <StoreContext>
    <ThemeProvider theme={theme}>
      <Content />
    </ThemeProvider>
  </StoreContext>
);

export default App;
