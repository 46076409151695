import React from "react";
import Store from "./Store";

const StoreContext = React.createContext();
const StoreConsumer = StoreContext.Consumer;

class StoreContextProvider extends React.Component {
  render() {
    return (
      <Store
        render={(values) => (
          <StoreContext.Provider value={values}>
            {this.props.children}
          </StoreContext.Provider>
        )}
      />
    );
  }
}

export { StoreContextProvider as default, StoreConsumer };
