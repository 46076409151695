import React from "react";
import api from "../dataStores/api";

import sanity from "../dataStores/sanity";
import config from "../amediaConfig/amediaConfig";

class Store extends React.Component {
  state = {
    publicationKey: null,
    publication: null,
    config: null,
    isLoading: true,
    products: [],
    siteInfo: {
      contactInfo: {},
      production: { },
      invoiceFee: "62.50",
    },
  };

  componentDidMount() {
    if (!this.state.publicationKey) {
      const pubKey = config(window).publicationKey;
      this.setState({ publicationKey: pubKey }, () => {
        this.getConfig(this.state.publicationKey);
        this.getProducts(this.state.publicationKey);
      });
    }
  }

  getConfig = (publicationKey) => {
    if (publicationKey) {
      const query = `
            {
                "config": *[_type == "publicationConfig" && publicationKey == $publicationKey][0] {
                    printDates,
                    publicationKey
                },
                "publication": *[_type == "publication" && publicationKey == $publicationKey][0]{
                        locale,
                        domains
                    }
            }`;
      const queryParams = { publicationKey };
      sanity
        .fetch(query, queryParams)
        .then((result) => this.setPublication(result));
    }
  };

  getProducts = (publicationKey) => {
    console.log("Fetch products", publicationKey);
    if (publicationKey) {
      api(`fetch/config/${publicationKey}`, "GET").then((result) =>
        this.setProducts(result)
      );
    }
  };

  setPublication = (result) => {
    this.setState({
      publication: result.publication,
      config: result.config,
      isLoading: false,
    });
  };

  setProducts = (result) => {
    this.setState({ products: result.products, siteInfo: result.publication });
  };

  getActions = () => ({
    getConfig: this.getConfig,
    getProducts: this.getProducts,
  });

  render() {
    return this.props.render({
      ...this.state,
      actions: this.getActions(),
    });
  }
}

export default Store;
