import React from "react";

import DialogBox from "./DialogBox";
import styles from "./Terms.module.css"

class Terms extends React.Component {
  render() {
    const { open, toggle, contactEmail } = this.props;
    return (
      <DialogBox
        open={open}
        handleClose={toggle}
        title={"Vilkår for annonsering i papiravis"}
      >
        <ul className={styles.list}>
          <li>
            Tjenesten gjelder kun for privatpersoner. Kontakt vår
            annonseavdeling dersom du er næringsdrivende og ønsker å inngå
            avtale om annonsering.
          </li>
          <li>
            Eventuelle feil i annonsen må meldes inn så snart som mulig:{" "}
            {contactEmail}. Avisen har ikke ansvar for evt feil annonsøren selv
            har skrevet i annonsebestilling. Vårt ansvar for eventuelle feil i
            annonseteksten, begrenser seg i alle tilfeller kun til det annonsen
            koster.
          </li>
          <li>
            Billedbruk skal være avklart med rettighetshaver før bruk.
          </li>
          <li>
            Alle annonser plasseres under den overskrift/vignett som mest
            korrekt angir innholdet i annonsen.
          </li>
          <li>
            Annonser som strider mot loven, er villedende, urimelige, virker
            støtende eller inneholder noe som bryter med samfunnets moralnormer
            og god tone, vil ikke bli publisert (for eksempel pornografi,
            alkohol, tobakk).
          </li>
          <li>
            Vi utleverer nødvendige opplysninger om annonse- og
            annonsørforhold til politi og offentlige myndigheter dersom vi blir
            bedt om det.
          </li>
          <li>
            Det er ikke tillatt å annonsere for dyrearter som ifølge
            Mattilsynet betegnes som ulovlige. I tillegg oppfordrer vi alle til
            å følge Mattilsynets retningslinjer for når dyret kan skilles fra
            sin mor.
          </li>
          <li>
            Det er forbudt å legge på prisen ved videresalg av billetter til
            kultur- og idrettsarrangement (Svartebørsloven). Annonser av typen
            «selges til høystbydende over (arrangørens) utsalgspris» og lignende
            annonser er dermed ulovlige og vil bli fjernet.
          </li>
        </ul>
      </DialogBox>
    );
  }
}

export default Terms;