import React from "react";
import Radio from '@mui/material/Radio';
import {IoRadioButtonOffOutline,IoRadioButtonOnOutline } from "react-icons/io5";

export default ({
  input: { checked, value, name, onChange, ...restInput },
  meta,
  selected,
  ...rest
}) => (
  <Radio
    icon={<IoRadioButtonOffOutline />}
    checkedIcon={<IoRadioButtonOnOutline />}
    {...rest}
    name={name}
    id={value}
    inputProps={restInput}
    onChange={onChange}
    checked={selected || !!checked}
    value={value}
  />
);
