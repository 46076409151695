import React from "react";
import { StoreConsumer } from "../ContextContainer/StoreContext";

import AdForm from "./AdForm";

class AdComponent extends React.Component {
  render() {
    return (
      <StoreConsumer>
        {(renderProps) => (
          <AdForm
            {...this.props}
            products={renderProps.products}
            siteInfo={renderProps.siteInfo}
            config={renderProps.config}
          />
        )}
      </StoreConsumer>
    );
  }
}

export default AdComponent;
